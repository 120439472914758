@import '../../../../../../../../colors.scss';

.form-product-save {
	text-align: right;
	margin-top: 20px;
}

.product-vat {
	font-style: italic;
	opacity: 0.6;
}

.form-product-container-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.delete-section-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-right: 10px;
		color: $red-addition;
		cursor: pointer;
		.section-label {
			margin: 0;
			padding-bottom: 10px;
		}
		.delete-section-icon {
			font-size: 30px;
		}
	}
}
