.card_action_help_text {
	border: 2px solid white;
	padding: 10px 30px;
	border-radius: 30px;
	transition: all 0.5s;
	cursor: pointer;
	font-size: 18px;
	text-align: center;
	margin: 0 auto;

	&:hover {
		background-color: white;
		color: #262957;
	}
}
