@font-face {
	font-family: 'FuturaHeavy';
	font-style: normal;
	src: url('../src/fonts/FuturaStd-Heavy.woff') format('woff'),
		url('../src/fonts/FuturaStd-Heavy.woff2') format('woff2'),
		url('../src/fonts/FuturaStd-Heavy.ttf') format('truetype');
}
@font-face {
	font-family: 'FuturaBold';
	font-style: normal;
	src: url('../src/fonts/FuturaStd-Bold.otf');
}
@font-face {
	font-family: 'FuturaBook';
	font-style: normal;
	src: url('../src/fonts/FuturaStd-Book.woff') format('woff'),
		url('../src/fonts/FuturaStd-Book.woff2') format('woff2'),
		url('../src/fonts/FuturaStd-Book.ttf') format('truetype');
}

@font-face {
	font-family: 'FuturaBookLight';
	src: url('../src/fonts/FuturaStd-Book-Light.ttf');
}
@font-face {
	font-family: 'NunitoSans';
	font-style: normal;
	src: url('../src/fonts/NunitoSans.eot'),
		url('../src/fonts/NunitoSans.woff') format('woff'),
		url('../src/fonts/NunitoSans.woff2') format('woff2'),
		url('../src/fonts/NunitoSans.ttf') format('truetype');
}

@import './colors.scss';

body {
	margin: 0;
	padding: 0;
	font-family: 'NunitoSans';
	background-image: linear-gradient(to bottom right, #fff, #e4e2ed);
	min-height: 100vh;
	height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'FuturaHeavy';
}

a {
	text-decoration: none;
}

.App {
	background: url('./img/dashboard_bgd.png') no-repeat center center fixed;
	background-size: cover;
	width: inherit;
	height: inherit;
	overflow-x: hidden;
	position: relative;
	min-height: 100vh;
	font-size: calc(10px + 2vmin);
	color: white;
	height: 100vh;
	width: 100vw;
}

.App-mask {
	background-image: linear-gradient(201deg, #e84249 0%, #262957 76%);
	width: inherit;
	height: inherit;
}

h1,
h2 {
	color: white;
	font-family: 'FuturaHeavy';
}

div[class^='MuiDialog-container'] h2 {
	color: #262957;
	font-family: 'FuturaHeavy';
}

.display-none {
	display: none;
}

.button-bold span {
	font-weight: bold;
}

.toast-style {
	font-size: 20px;
}

.button-valid {
	font-family: 'FuturaBook' !important;

	&:hover {
		span {
			font-family: 'FuturaHeavy' !important;
		}
	}
}

.button-cancel {
	&:hover {
		color: #5ac8f5 !important;
		background-color: rgba(255, 255, 255, 0) !important;
	}
}

.pastille-after {
	&:after {
		content: '\2022 ';
		color: #b30015;
	}
}

.pastilla {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: $ciel-addition;
	margin-left: 10px;
	border: 3px solid $ciel-addition-light;
}
