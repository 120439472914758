@import '../../colors.scss';

body {
	margin: 0;
	padding: 0;
}

.edit-modal {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	left: 25%;
	height: 60%;
	width: 50%;
	min-height: 685px;
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 920px) {
		left: 0;
		width: 100%;
		min-height: 550px;
	}
}

.edit-modal-small {
	min-height: 600px;
	height: 44%;
}

.edit-modal-container {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 50%;
}

.edit-popup {
	position: fixed; /* Stay in place */
	z-index: 3; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgba(0, 0, 0, 0.6); /* Fallback color */
	z-index: 11;
}

.crop-container {
	text-align: center;
	position: relative;
	width: 100%;
	height: 100%;
	margin: auto;
	div:first-child {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
}

.cropper {
	height: 45%;
	margin-bottom: 50px;
	.controls {
		position: relative;
		top: -40px;
	}
}

.add-picture {
	position: relative;
	width: 92%;
	height: 400px;
	margin: 0;
	display: flex;
	padding: 4%;
	.add-picture-container {
		width: 92%;
		height: 92%;
		border: 2px dashed #ababab;
		border-radius: 10px;
		background-color: #f6f6f6;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: $blue-addition;
		font-family: 'FuturaBook';
		.picto-pola {
			width: 60px;
		}
		.gliss-text {
			text-align: center;
			width: 130px;
			color: $red-addition;
		}
		.input-file {
			display: none;
		}
		.withBackground {
			font-family: 'FuturaBook';
			padding: 0 50px;
			margin-top: 14px;
			&:hover {
				background: $red-addition;
				font-family: 'FuturaHeavy';
			}
		}
		.dropzone {
			outline: 0;
			position: absolute;
			width: 93%;
			height: 93%;
		}
	}
}

.controls {
	box-sizing: border-box;
	position: relative;
	width: 40%;
	margin: 0 auto;
	padding: 0 16px;
	height: 80px;
	display: flex;
	align-items: center;
	.small-size {
		width: 20px;
		margin-right: 10px;
	}
	.big-size {
		width: 30px;
		margin-left: 10px;
	}
}

.input-container {
	display: flex;
	flex-direction: column;
	padding: 0 10% 4% 10%;
	height: 24%;
	label {
		font-size: 20px;
		font-family: 'FuturaBook';
		color: #7e8198;
	}
	div {
		margin: 0;
	}
}

.button-container {
	align-self: flex-end;
	margin: 0 20px 20px 0;
	.withoutBackground {
		color: $blue-addition;
		font-family: 'FuturaBook';
		padding: 0 25px;
		&:hover {
			background: none;
			color: $ciel-addition;
		}
	}
	.withBackground {
		font-family: 'FuturaBook';
		padding: 0 25px;
		&:hover {
			background: $red-addition;
			font-family: 'FuturaHeavy';
		}
	}
}

.galery-container {
	@media (min-width: 920px) {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 4%;
	}
	@media (max-width: 920px) {
		overflow-x: auto;
		white-space: nowrap;
		text-align: center;
		height: inherit;
	}
	.galery-item {
		margin: 0 15px;
		width: 100px;
		height: 100px;
		border-radius: 4px;
		cursor: pointer;
		@media (max-width: 920px) {
			width: 60px;
			height: 60px;
			margin: 0 10px;
			display: inline-block;
			vertical-align: middle;
		}
	}
	.galery-link-to {
		border: 1px solid #777;
		@media (min-width: 920px) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.item-disabled {
		opacity: 0.5;
		cursor: initial;
	}
}
