@charset "UTF-8";
.loader-container-med {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  z-index: 5000;
  display: inline-block;
  text-align: center; }

.loader-text-med {
  position: absolute;
  /* postulat de départ */
  top: calc(50% + 100px);
  left: 50%;
  /* à 50%/50% du parent référent */
  transform: translate(-50%, -50%);
  width: 600px;
  height: 100px;
  color: white;
  font-family: 'FuturaHeavy';
  font-size: 32px; }

.loader-container-med-hidden {
  display: none; }

.pulse-med {
  width: 95px;
  height: 95px;
  border-radius: 1%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(181, 46, 44, 0.8);
  animation: pulse 1s infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

.pulse-med img {
  margin-left: -27px;
  margin-top: -27px; }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(232, 66, 73, 0.8);
    box-shadow: 0 0 0 0 rgba(232, 66, 73, 0.8); }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(232, 66, 73, 0);
    box-shadow: 0 0 0 20px rgba(232, 66, 73, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(232, 66, 73, 0);
    box-shadow: 0 0 0 0 rgba(232, 66, 73, 0); } }

.laddition-loader-container-med {
  min-height: 100vh;
  min-width: 100%;
  position: absolute;
  background-image: linear-gradient(141deg, #1f1919 0, #363636 71%, #463f3f 100%);
  z-index: 999999999999999; }

.laddition-loader-container-med__hidden .pulse-med {
  animation: none; }
