@import '../../../../colors.scss';

#container-info-restau {
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0px;
	display: flex;
	flex-direction: column;
}

#form-info-restau {
	display: flex;
	flex-direction: column;
	margin-top: -20px;
}

#form-info-1 {
	display: flex;
	flex-grow: 1;
	margin: 5px;
}

#form-info-2 {
	display: flex;
	flex-grow: 1;
	margin: 5px;
}

.form-section-50 {
	flex-grow: 1;
	margin: 5px;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.form-section-row {
	display: flex;
	margin: -4px;
}

.flex-item-20 {
	flex-grow: 20;
}
.flex-item-80 {
	flex-grow: 80;
}
.flex-item-33 {
	flex-grow: 33;
}
.flex-item-66 {
	flex-grow: 66;
}
.flex-item-100 {
	flex-grow: 100;
}

.tab-module {
	width: 50%;
	background-color: #fff;
	border-radius: 6px;
	color: #4a4a4a;
	box-shadow: 0 8px 16px rgba(1, 1, 1, 0.19);
	padding: 20px;
	margin-top: 52px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
}

#infoRestau-name-value {
	color: $red-addition;
	font-size: 3.3em;
	margin-left: 10px;
	text-transform: uppercase;
	font-family: 'FuturaBold';
	line-height: 50px;
}

.info-Restau-section-title {
	color: #2b305e;
	font-size: 27px;
}

#long-lat-display {
	width: 100%;
	text-align: center;
	margin: auto;
	position: relative;
	top: -37px;
	background-color: white;
	padding-top: 17px;
	padding-bottom: 17px;
	border-radius: 5px;
	font-weight: 600;
	font-family: 'FuturaBook';
}

#form-info-restau textarea,
#form-info-restau input {
	border: none;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
	margin-top: 10px;
	margin-bottom: -1px;
}

#form-info-restau input {
	height: 25px;
}

#form-info-restau label {
	font-size: 25px;
	font-family: 'FuturaBook';
	color: #7e8198;
}

.form-description-container {
	width: 100%;
	position: relative;
}

.form-caracters-remaning {
	color: #7e8198;
	width: 200px;
	text-align: right;
	position: absolute;
	right: 0;
}

.locate-button {
	background-color: $red-addition;
	color: white;
	border: none;
	padding: 10px;
	padding: 10px;
	text-align: center;
	vertical-align: center;
	border-radius: 15px;
	cursor: pointer;
	font-family: 'FuturaBook';
	font-size: 15px;
}

.locate-button-init:hover {
	opacity: 0.7;
}

.locate-button-progress {
	background-color: #bbbbbb;
}

.locate-button-success {
	background-color: #6ccc68;
}

.locate-button-fail {
	background-color: #fa8888;
}
