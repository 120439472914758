.button-payplus-container {
	width: 300px;
	display: flex;
	justify-content: center;
	button {
		width: 250px;
		background-color: #e84249;
		color: #fff !important;
		box-shadow: none;
		border: none;
		font-size: 15px !important;
		padding: 9px 30px !important;
		border-radius: 5px;
		font-family: 'FuturaHeavy';
		cursor: pointer;
		transition: all 500ms;
	}

	button:hover {
		opacity: 0.6;
	}

	button.button-playplus-disabled {
		cursor: default;
		transition: none;
		font-family: 'FuturaBook';
		opacity: 0.6;
	}
}

.pay-plus-activated {
	width: 250px;
	display: flex;
	color: #5ac8f5;
	align-items: center;
	font-size: 15px;
	height: 36px;
}

.print-ticket-container {
	background-color: #fff;
	border-radius: 10px;
	display: flex;
	margin-top: 30px;
	overflow: hidden;
	color: #262957;
	font-weight: bold;
	font-family: 'FuturaHeavy';

	.print-title {
		font-size: 20px;
	}

	.dimension {
		font-weight: normal;
		font-family: 'FuturaBook';
		opacity: 0.7;
	}

	.image {
		background-color: #e7e7e5;
		background-image: url('https://cdn.laddition.com/23/qr-code-support.png');
		background-position: 0 5px;
		background-repeat: no-repeat;
		background-size: 102%;
		width: 175px;
	}
	.right {
		flex-grow: 1;
		padding: 20px;
	}

	.buttons {
		display: flex;
		font-family: 'FuturaBook';
	}

	.buttons a {
		background-color: #e84249;
		color: white;
		font-size: 18px;
		padding: 7px 18px;
		text-align: center;
		border-radius: 30px;
	}

	.buttons a:nth-child(2) {
		margin-left: 10px;
	}
}

.ticket-tip-container {
	margin-top: 30px;
	margin-right: auto;
	margin-left: auto;
	width: 680px;
}

.ticket-tip-container-inside {
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.ticket-tip-title {
	display: flex;
	justify-content: space-between;
}

.ticket-tip-add {
	cursor: pointer;
	font-weight: bold;
}
