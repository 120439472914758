@import '../../colors.scss';
.tabs-container {
	width: 100%;
	border-radius: 6px;
	color: #4a4a4a;
	display: flex;
	justify-content: center;
	align-content: center;
	position: relative;
	margin-top: 20px;

	a {
		width: 100%;
		text-decoration: none;
		color: #4a4a4a;
		text-align: center;

		p {
			color: #262957;
			font-family: 'FuturaHeavy';
			font-size: 19px;
			line-height: 24px;
		}
	}
}

%line {
	content: '';
	position: absolute;
	margin-top: -3px;
	left: 0;
	right: 0;
	border-bottom: 2px solid #cecece;
}

.header-container {
	text-align: center;
	z-index: 2;
	display: flex;
	justify-content: center;
	padding: 0;
	li {
		&:nth-child(2),
		&:nth-child(3) {
			a {
				&::after {
					@extend %line;
					left: 0;
					right: 0;
				}
				&.topLine {
					&::after {
						top: 1.73rem;
					}
				}
			}
		}
		&:last-child {
			a {
				&::after {
					@extend %line;
					left: 0;
					width: 50%;
					right: 0;
				}
				&.topLine {
					&::after {
						top: 1.73rem;
					}
				}
			}
		}
		&:first-child {
			a {
				&::before {
					content: '';
					position: absolute;
					bottom: 1px;
					right: 0;
					width: 50%;
					border-bottom: 2px solid #cecece;
				}
				&.topLine {
					&::before {
						top: 1.73rem;
					}
				}
			}
		}
	}
	li {
		display: inline;
		position: relative;
		width: 100%;
		a {
			display: inline-block;
			position: relative;
			cursor: pointer;
			hr {
				background-color: $blue-addition;
				height: 4px;
				width: 0.5rem;
				text-align: center;
				margin: 0 auto;
				border: none;
				position: relative;
				z-index: 3;
			}
			p {
				font-weight: bold;
				font-size: 12px;
				font-family: 'FuturaBold';
				text-transform: uppercase;
				margin-top: 0;
				color: #7e8198 !important;
			}
		}
	}
	.active {
		p {
			color: $red-addition !important;
		}
		hr {
			width: 5rem;
			background-color: $red-addition;
			animation: separator-width 300ms ease-out forwards;
		}
	}
	.disabled-section {
		pointer-events: none;
		opacity: 0.6;
	}
}

@keyframes separator-width {
	0% {
		width: 0;
	}
	100% {
		width: 25%;
	}
}
