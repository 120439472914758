.products-selector-modal-container {
	display: flex;
	flex-direction: row;
	height: 84%;

	.section-category {
		width: 20%;
		height: 100%;
		padding: 0;
		h2 {
			margin: 0;
			height: 10%;
			display: flex;
			align-items: center;
		}
		.products-category-container {
			height: 90%;
			padding: 0;
			span {
				color: #262957;
			}
		}
	}
	.section-products {
		width: 60%;
		height: 100%;
		margin: 0 20px;
		.section-products-header {
			margin: 0;
			height: 10%;
			h2 {
				margin: 0;
			}
		}
		.section-product-body {
			height: 90%;
			#select-all-container {
				height: 13%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				#placeholder-select-all {
					float: right;
					margin: 35px 35px 0;
				}
				#select-all {
					margin-right: 4px;
					color: #262957;
				}
			}
			.productsSelector-products-list {
				padding: 0;
				height: calc(87% - 1px);
			}
		}
	}
	.section-recap {
		width: 20%;
		height: 100%;
		h2 {
			margin: 0;
			height: 10%;
			display: flex;
			align-items: center;
		}
		.section-selected-container {
			width: auto;
			height: 90%;
			background-color: #fff;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			overflow: hidden;
			box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);

			.section-selected {
				overflow-y: auto;
				height: 88%;
				margin-bottom: 50px;
				color: #262957;
			}

			.item-selected-menu {
				font-size: 20px;
				font-family: 'FuturaBook';
				margin-left: 10px;
			}

			.chip-item {
				margin: 8px;
				width: calc(100% - 25px);
				font-family: FuturaBook;
				border-radius: 4px;
				background-color: #e2f5fd;
				padding: 10px 0px 10px 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.chip-name {
					flex-grow: 15;
					vertical-align: bottom;
					line-height: 30px;
				}

				.chip-delete {
					color: red;
					flex-grow: 1;
					cursor: pointer;
					display: flex;
					align-items: center;
				}
			}
		}
	}
}
.selector-modal-content {
	background-image: linear-gradient(#f9f8fb, #ebe9f1);
	font-family: 'FuturaBook';
	padding: 0 24px !important;
	overflow: hidden !important;
	height: 100%;
	h1 {
		height: 8%;
		margin: 0;
		display: flex;
		align-items: center;
	}

	h2 {
		color: #2b2e62;
	}
	.menu-and-name-container {
		display: flex;
		align-items: center;
		height: 8%;
	}
}

.section-products {
	.section-products-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.section-product-body {
		background-color: white;
		height: 100%;
		.section-product-body-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 80px;
			#search-container {
				position: relative;
				padding: 0;
				margin: 0;
				display: flex;
				align-items: center;
				margin-left: 20px;
				box-shadow: 0px 0px 10px 1px #cecece;
				border-radius: 4px;

				#placeholder-search {
					width: 360px;
					height: 43px;
				}

				#standard-search {
					background-color: white;
					border: none;
					border-radius: 8px;
					padding: 5px 40px 5px 20px;
					height: 30px;
					width: 300px;
				}

				#standard-search::placeholder {
					font-style: italic;
					font-size: 15px;
					opacity: 0.5;
					font-family: 'FuturaBook';
				}

				img {
					position: absolute;
					width: 25px;
					right: 10px;
				}
			}
		}
		.item-selectable-parent {
			li {
				height: 100%;
				.item-selectable {
					height: 100%;
					.item-selectable-container {
						display: flex;
						justify-content: space-between;
						color: #262957;
						span {
							margin-right: 20px;
						}
					}
				}
			}
		}
	}

	#item-selectable-empty {
		height: 520px;
		padding: 50px;
		text-align: center;
		p {
			font-size: 1.5em;
			text-transform: uppercase;
			opacity: 0.4;
			vertical-align: center;
		}
	}
}

#item-selectable-content-placeholder {
	height: 544px;
	width: 100%;
}

#products-selector-modal-action {
	background-color: #f7f7f7;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	width: initial;
	height: 50px;
	padding: 10px;
	position: sticky;
	bottom: 0;
	display: flex;
	justify-content: space-around;
	button {
		font-size: 20px;
		span {
			font-family: 'FuturaBook';
		}
	}

	#products-selector-modal-valid {
		width: 150px;
	}
}

.menu-selector {
	padding: 1% 0;
	width: 270px;
	border-radius: 10px;
	margin-right: 20px;
}

.menu-selector-disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

.section-empty {
	text-align: center;
	font-size: 1em;
	text-transform: uppercase;
	opacity: 0.4;
	vertical-align: center;
}

.products-category-container {
}

.selectable-products-list {
	height: 100% !important;
}

@media (min-width: 1600px) {
	.menu-selector {
		height: 1%;
	}
	.products-selector-modal-container
		.section-products
		.section-product-body
		#select-all-container {
		height: 10%;
	}

	.products-selector-modal-container
		.section-products
		.section-product-body
		.productsSelector-products-list {
		height: calc(90% - 1px);
	}
}
