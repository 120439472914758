@import '../../../../colors.scss';

.export-banner-container {
	width: 680px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h2 {
		align-self: flex-start;
	}
}

.banner-export-empty {
	height: 150px;
	width: 600px;
	border-radius: 10px;
	border: 7px dashed $red-addition;
	color: $red-addition;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	p {
		font-size: 24px;
	}
}

.banner-export-mini {
	height: 200px;
}

.banner-export-image {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	img {
		margin-bottom: 20px;
	}
}
