@import '../../../../../../../../colors.scss';
.export-card-container {
	background-color: white;
	width: 590px;
	height: 100px;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 0px 20px 1px #cecece;
	margin: 10px;
	color: $blue-addition;
	align-items: center;
	display: flex;
	flex-direction: column;

	.export-card-left {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 100%;

		.export-card-active {
			font-size: 18px;
			color: $ciel-addition;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			margin-left: auto;
			p {
				margin: 0;
				width: 100px;
				text-align: right;
			}

			.pastilla {
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background-color: $ciel-addition;
				margin-left: 10px;
				border: 3px solid $ciel-addition-light;
			}
		}

		h2 {
			font-family: 'FuturaBold';
			font-size: 25px;
			color: $blue-addition;
		}
	}

	.export-card-right {
		display: flex;
		width: 100%;
		margin-top: auto;

		.export-card-date {
			font-size: 15px;
			color: gray;
			position: relative;
			bottom: -30px;
		}

		.export-card-actions {
			display: flex;
			margin-left: auto;
			svg {
				color: $red-addition;
				cursor: pointer;
				font-size: 30px;
			}

			button {
				font-size: 20px;
				&:hover {
					background-color: transparent;
				}
			}
			.secondary-button {
				font-size: 18px;
				text-decoration: underline;
			}

			.red-button {
				margin: 0;
				button {
					&:disabled {
						span {
							color: #5d5d5d;
							font-family: 'FuturaBook';
						}
						background-color: $grey-addition !important;
					}
				}
			}
			.primary-button {
				span {
					font-size: 16px;
					font-family: 'FuturaHeavy';
				}
			}
		}
	}
}

$spCol: rgb(180, 180, 180);
.sp {
	width: 16px;
	height: 16px;
	clear: both;
	margin: auto auto auto 10px;
}

/* Spinner Circle Rotation */
.sp-circle {
	border: 4px rgba($spCol, 0.25) solid;
	border-top: 4px rgba($spCol, 1) solid;
	border-radius: 50%;
	-webkit-animation: spCircRot 0.6s infinite linear;
	animation: spCircRot 1s infinite linear;
}
@-webkit-keyframes spCircRot {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}
@keyframes spCircRot {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
