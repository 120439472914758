.ubereats_pos_status_container {
	margin-top: 20px;
	font-size: 20px;
	font-weight: normal;
	text-align: center;
}

.ubereats_pos_status {
	font-weight: bold;
	text-decoration: underline;
}

.ubereats_pos_status_enabled {
	color: rgb(23, 183, 23);
}

.ubereats_pos_status_disabled {
	color: #00509b;
}

.ubereats_pos_status_unknow {
	color: #f6950e;
}
