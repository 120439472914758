$bleu-fonce: #2b2e62;
$red-addition: #e84249;

.form-product-title {
	color: $bleu-fonce;
	font-family: 'FuturaHeavy';
	margin-bottom: 7px;
}

.form-product-input,
.form-product-description {
	margin-bottom: 20px;

	input {
		width: 150px;

		&[type='text'] {
			width: 250px;
		}
	}

	textarea {
		width: 300px;
		height: 80px;
		min-height: 50px;
		max-height: 400px;
	}

	input,
	textarea {
		resize: vertical;
		border-radius: 5px;
		padding: 5px 10px 5px 10px;
		border: 1px solid #e7e7e7;
		color: #ababab;
		font-family: 'FuturaBook';
		font-size: 16px;
		&:focus {
			color: $bleu-fonce;
		}
	}
}

.form-product-switch {
	display: flex;
	align-items: center;

	form-product-switch-name {
		width: 300px;
	}
}

.form-product-visibility {
	display: flex;
	align-items: center;

	svg {
		margin-left: 10px;
		margin-top: 5px;
		cursor: pointer;
	}
}

.form-product-visibility-disabled {
	cursor: not-allowed;
	.form-product-title {
		opacity: 0.3;
	}

	svg {
		opacity: 0.3;
		cursor: not-allowed;
	}
}

.form-product-visibility-off {
	color: #ababab;
}

.form-product-image-import {
	background-color: #fff;
	color: $red-addition;
	border: none;
	border-radius: 7px;
	font-family: 'FuturaHeavy';
	font-size: 15px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
	padding: 7px 30px 5px 30px;
	cursor: pointer;
}
