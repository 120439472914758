.content-container {
  .subHeader-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 80px 0 80px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
    }

    .left-container,
    .right-container {
      flex: 1;
      display: flex;
      justify-content: start;
      text-align: start;
    }

    .center-container {
      flex: 1;
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;

      h1 {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .partners-container {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;

    .partners-subcontainer {
      padding-top: 50px;
      padding-bottom: 50px;
      max-width: 800px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}