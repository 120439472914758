@import '../../../../../../../../../../../colors.scss';
.form-input-image-card {
	position: relative;
	border-radius: 10px;
	width: 250px;
	max-height: 400px;
	img {
		width: 100%;
		height: auto;
		border-radius: 10px;
	}

	button {
		position: absolute;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		border: none;
		color: $red-addition;
		top: 5px;
		right: 5px;
		background-color: white;
		cursor: pointer;
		transition: all 500ms;
	}

	button:hover {
		background-color: $red-addition;
		color: white;
	}
}
