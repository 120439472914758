@import '../../colors.scss';
.event-category-title {
	font-size: 2em;
	margin: 10px;
	display: block;
	margin-block-start: 1.33em;
	margin-block-end: 1.33em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-family: 'FuturaBook';
	color: $bleu-fonce;
}

.event-category-title-selected {
	font-family: 'FuturaHeavy';
	color: $red-addition;
}

.event-category-panel {
	background-color: rgba($color: #000000, $alpha: 0) !important;
	box-shadow: none !important;
	min-width: 400px;
	width: 60%;
}

.event-category-panel svg {
	color: $red-addition;
	font-size: 1.8em;
}

.event-paper {
	font-family: 'FuturaBook';
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	background-color: #fefefe;
	padding: 20px 30px 20px 30px;
	margin: 10px;
	color: $bleu-fonce;
	width: 100%;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	-webkit-transition: all 200ms;
	transition: all 200ms;
}

.event-paper:hover {
	background-color: #f8f7f7;
	*transform: skew(0deg, 1deg);
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3),
		0px 2px 2px 0px rgba(0, 0, 0, 0.2), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}
