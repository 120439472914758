@import '../../../../../colors.scss';
.webhook-add-button {
	width: 613px;
	background-color: #fefefe;
	color: $red-addition;
	border: 1px solid $red-addition;
	border-radius: 4px;
	height: 40px;

	text-align: center;
	vertical-align: center;
}

.webhook-add-button span {
	font-family: 'FuturaBold';
	font-size: 15px;
	margin-left: 20px;
	text-transform: capitalize;
}

.webhook-add-container {
	margin-top: 32px;
	margin-left: 8px;
}
