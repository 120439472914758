.blue {
	color: #5ac8f5;
}

.blury {
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	/*  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px); */
	top: 0;
	left: 0;
	z-index: 4;
}

.item-enter {
	opacity: 0;
}
.item-enter-active {
	opacity: 1;
	transition: opacity 1000ms ease-in;
}
.item-exit {
	opacity: 1;
}
.item-exit-active {
	opacity: 0;
	transition: opacity 1000ms ease-in;
}

/* @supports not (backdrop-filter: blur(4px)) {
  .blury {
    background-color: rgba(0, 0, 0, 0.8);
  }
} */

.z-index0 {
	z-index: 0;
}

.z-index5 {
	z-index: 5 !important;
}

.divider {
	width: 80%;
	height: 3px;
	border-radius: 3px;
	background-color: #ababab;
	opacity: 0.5;
	margin: 0 auto;
}

.partnerconfig-content-container {
	max-width: 100%;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin: 0 100px;
	padding-bottom: 40px;
}

.title-content {
	margin: 0 120px;
}

.partners-link-espace-client {
	position: absolute;
	left: 50px;
	top: 70px;

	a {
		color: white;
		display: flex;
		align-items: center;
		font-size: 20px;
	}
}

.search-bar {
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	margin: 15px auto;
	cursor: pointer;
	transition: all 0.5s;
	svg {
		fill: #292929;
		margin: 0 1rem;
	}
	input {
		height: 38px;
		font-size: 16px;
		width: 250px;
		opacity: 1;
		color: #292929;
		border: none;
		outline: none;
		font-family: 'FuturaBook';
		border-radius: 19px;
		transition: all 0.5s;
	}
}

.search-bar-active {
	width: 300px;
	justify-content: left;
}

.search-tag-content {
	padding: 0 120px;
	background-color: transparent;
	font-family: 'FuturaBook';
	display: flex;
	align-items: center;
	.search-tag-container {
		display: flex;
		align-items: center;
		width: 100%;
		flex-wrap: wrap;
		justify-content: center;

		.tag-container {
			height: 39px;
			background-color: rgba(255, 255, 255, 0.3);
			display: flex;
			align-items: center;
			border-radius: 19.5px;
			transition: all 0.3s linear;
			padding: 0 35px;
			margin: 3px 10px;

			p {
				margin: 0 10px;
				font-size: 16px;
				color: white;
				transition: all 0.3s linear;
			}
			&:hover {
				background: #5ac8f5;
				cursor: pointer;
				p {
					color: #fff;
				}
			}
		}
		.tag-selected {
			p {
				color: #fff !important;
			}
			background: #5ac8f5 !important;
		}
	}
	.separator {
		height: 3px;
		width: 100%;
		opacity: 0.5;
		background: #ababab;
		border-radius: 2.5px;
		margin-top: 10px;
	}
}

.step-header img {
	margin-top: 2px;
}

// EN ATTENTE D'ETRE DISPATCHE AU BON ENDROIT
.partnerconfig-export-container {
	width: 30%;
	margin: 20px;
	position: relative;
	.partnerconfig-partner-card {
		width: 100%;
		background-color: #fff;
		border-radius: 20px;
		display: flex;
		margin: 0 auto;
		flex-direction: column;
		box-shadow: 0 6px 14px rgba(0, 0, 0, 0.23);
		min-height: 184px;
		position: relative;
		transition: all 0.3s linear;
		min-height: 180px;
		height: calc(100% - 30px);
		img {
			width: 60%;
			cursor: initial;
		}
		&:hover {
			box-shadow: 0 12px 28px #979797;
		}

		/* Hide scrollbar for Chrome, Safari and Opera */
		:-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */

		.deliveroo-id {
			span {
				font-size: 20px;
				font-weight: bold;
				background-color: #e6e6e6;
				text-align: center;
				padding: 5px 10px;
				border-radius: 5px;
			}
		}
	}
	.partner-with-url {
		&:hover {
			//cursor: pointer;
			.bottom-card p {
				color: #e84249;
			}
			img {
				//cursor: pointer;
			}
		}
	}
}

@media only screen and (min-width: 1500px) {
	.partnerconfig-export-container {
		.partnerconfig-partner-card {
			min-height: 200px;
		}
	}
}

@media only screen and (min-width: 2000px) {
	.partnerconfig-export-container {
		.partnerconfig-partner-card {
			min-height: 250px;
		}
		.content-card-p {
			font-size: 19px;
		}
		.button-partner-container {
			.red-button {
				span {
					font-size: 16px;
				}
			}
		}
	}
	.expand-content {
		max-height: 610px !important;
	}
}

@media only screen and (min-width: 2500px) {
	.partnerconfig-export-container {
		.partnerconfig-partner-card {
			min-height: 300px;
		}
	}
}
