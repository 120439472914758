@import '../../../../colors.scss';
.export-see-more {
	color: white;
	text-decoration: underline;
	font-family: 'FuturaBook';
	font-size: 15px;
	text-align: right;
	cursor: pointer;
	*margin: 0px 15px 20px 0px;
}

.export-see-more:hover {
	opacity: 0.75;
}

.export-loading {
	text-align: center;
	font-family: 'FuturaBook';
	font-size: 2em;
	color: $blue-addition;
	animation: color-change 2s infinite;
	padding-bottom: 24px;
}

@keyframes color-change {
	0% {
		color: $blue-addition;
	}
	50% {
		color: #fefefe;
	}
	100% {
		color: $blue-addition;
	}
}

.export-container-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.export-container-menu-configuration {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 15px 0px 15px;
	width: 680px;
	align-items: center;
}

.export-title {
	font-family: 'FuturaBookLight';
	font-weight: lighter;
	font-size: 25px;
	text-align: left !important;
	color: white;
}
