.gallery-item {
	width: 100px;
	height: 100px;
	border: 1px solid #aaaaaa;
	margin: 10px;
	cursor: pointer;
}

.item-disabled {
	opacity: 0.5;
	cursor: initial;
}
