.backgroundCircle-container {
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.backgroundCircle-container svg {
	width: 100%;
	height: 100%;
}

#oval1,
#oval4,
#oval6 {
	animation-name: i;
	animation-duration: 5s;
	animation-delay: 1s;
	animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
	animation-iteration-count: infinite;
}

#oval2,
#oval5 {
	animation-name: j;
	animation-duration: 5s;
	animation-delay: 2s;
	animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
	animation-iteration-count: infinite;
}

#oval3 {
	animation-name: h;
	animation-duration: 5s;
	animation-delay: 1s;
	animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
	animation-iteration-count: infinite;
}

#oval7 {
	animation-name: k;
	animation-duration: 15s;
	animation-delay: 2s;
	animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
	animation-iteration-count: infinite;
}

/*** KEYFRAMES ***/

@keyframes i {
	0% {
		transform: rotateX(0deg) translateY(0);
	}
	50% {
		transform: rotateX(0deg) translateY(15px) translateX(15px);
	}
	100% {
		transform: rotateX(0deg) translateY(0);
	}
}

@keyframes j {
	0% {
		transform: rotateX(0deg) translateY(0);
	}
	50% {
		transform: rotateX(0deg) translateY(15px);
	}
	100% {
		transform: rotateX(0deg) translateY(0);
	}
}

@keyframes h {
	0% {
		transform: rotateX(0deg) translateY(0);
	}
	50% {
		transform: rotateX(0deg) translateY(15px) translateX(-15px);
	}
	100% {
		transform: rotateX(0deg) translateY(0);
	}
}

@keyframes k {
	0% {
		transform: rotateX(0deg) translateY(0);
	}
	15% {
		transform: rotateX(0deg) translateY(-30px) translateX(-30px);
	}
	30% {
		transform: rotateX(0deg) translateY(-60px) translateX(0);
	}
	45% {
		transform: rotateX(0deg) translateY(-30px) translateX(30px);
	}
	60% {
		transform: rotateX(0deg) translateY(-90px) translateX(60px);
	}
	75% {
		transform: rotateX(0deg) translateY(0) translateX(90px);
	}
	100% {
		transform: rotateX(0deg) translateY(0);
	}
}
