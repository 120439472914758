@import '../../../../colors.scss';
.partner-option-container {
	color: $blue-addition;
	min-width: 630px;
	margin-top: 30px;
	margin-right: auto;
	margin-left: auto;
	width: 680px;

	.partner-option-subcontainer {
		background-color: white;
		border-radius: 5px;
		padding: 30px;
	}
	.partner-option-empty {
		font-family: 'FuturaBook';
		text-align: center;
		font-style: italic;
	}
}

.option-container {
	display: flex;
	align-items: center;

	.option-name {
		flex-grow: 5;
		font-size: 17px;
	}

	.option-name-disabled {
		opacity: 0.6;
	}

	.option-name-need_update {
		font-size: 17px;
		opacity: 0.6;
		font-weight: bold;
		font-style: italic;
	}
}

.option-container-child {
	padding-left: 60px;
	*border-left: 1px solid #292929;
}

.option-child-arrow svg {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	position: relative;
	top: 6px;
	margin-right: 5px;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
	background-color: #80d2f3 !important;
}

.option-settings-advanced {
	font-size: 15px;
	display: flex;
	align-items: center;
	transition: background-color 200ms;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
}

.option-settings-advanced:hover {
	background-color: #26295710;
}

.option-input-placeholder {
	width: 58px;
	padding: 0;
	padding-top: 2px;
}

.option-category-label {
	font-weight: bold;
	font-size: 22px;
	margin-bottom: 0;
}

.option-event-name {
	font-style: italic;
	font-family: 'FuturaBook';
	font-size: 20px;
	margin-top: 30px;
}
