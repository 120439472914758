@import '../../colors.scss';
.red-button {
	margin: 20px;
}
.red-button button:disabled {
	background-color: rgba(232, 66, 73, 0.5) !important;
}

.red-button button {
	background-color: $red-addition;
	color: #fff !important;
	font-family: 'NunitoSans' !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	text-transform: none !important;
	font-size: 14px !important;
	padding: 5px 30px !important;
	border-radius: 40px;
	&:hover {
		box-shadow: none;
		background-color: #ee5056 !important;
	}
}
