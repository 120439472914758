.partnerconfig-configuration-container {
	width: 100% !important;
	margin: 0 !important;

	.mewsSystemsDocLink {
		overflow: scroll;
		text-align: center;
		a {
			color: #262957;
			font-family: 'FuturaBook';
			font-size: 13px;
		}

		svg {
			width: 20px;
		}
	}
}
.configuration-bottom {
	bottom: 0;
	position: absolute;
	width: 100%;
	display: flex;
	align-items: center;
	font-family: 'FuturaBook';
	font-size: 16px;
	color: #e84249;
	min-height: 51px;
	.progress-bar-container {
		width: 40%;
		display: flex;
		align-items: center;
		span {
			font-size: 12px;
			padding: 0 10px;
			transition: color 0.3s linear;
		}
		.progress-bar {
			width: 50%;
			border-radius: 12px;
			div {
				background-color: #e84249;
			}
		}
	}
	.configuration-button-next {
		transition: color 0.3s linear;
		margin-left: auto;
		margin-right: 10px;
		cursor: pointer;
	}
	.disabled {
		color: rgba(255, 255, 255, 0.5) !important;
	}
	&:hover {
		background-color: #e84249;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		.progress-bar-container {
			span {
				color: #fff;
			}
			.progress-bar {
				div {
					background-color: #fff;
				}
			}
		}
		.configuration-button-next {
			color: #fff;
		}
	}
}

.configuration-bottom-active {
	background-color: #e84249;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	.progress-bar-container {
		span {
			color: #fff;
		}
		.progress-bar {
			div {
				background-color: #fff !important;
			}
		}
	}
	.configuration-button-next {
		color: #fff;
		margin-left: auto;
	}
}

.step-header {
	display: flex;
	align-items: center;
	padding: 0 16px;
	img {
		width: 25% !important;
		position: relative !important;
		margin-left: auto;
		left: 0 !important;
	}
	h3 {
		margin: 0;
		color: #262957;
		font-family: 'FuturaBold';
		font-size: 17px;
		font-weight: 700;
		text-transform: uppercase;
		display: flex;
		align-items: center;

		.card_action_help {
			margin-left: 10px;
		}
	}
}
.link-ubereats-signup {
	color: #e84249;
	font-size: 11px;
	text-align: center;
	margin-bottom: 5px;
	cursor: pointer;
}

.uuid-container {
	display: flex;
	flex-direction: column;

	padding: 0 16px 50px;
	.partner-input-label {
		color: #262957 !important;
	}

	.input-button {
		width: 100%;
		margin-bottom: 0;
		margin-top: 0px;
	}

	a:hover {
		text-decoration: underline;
	}
}

.input-button-disabled {
	background-color: rgba(171, 171, 171, 0.2) !important;
	cursor: initial !important;
}

.content-partner-token {
	display: flex;
	flex-direction: column;
	align-items: center;
	.content-card-p {
		margin: 0;
	}
}

.expand-container {
	width: 30%;
	margin: 20px;
	z-index: 4;
	.content-card-3 {
		padding-bottom: 50px;
		.content-card-p {
			margin: 0;
		}
		.red-button {
			margin: 10px;
			text-align: center;
		}
		a {
			.red-button {
				margin: 3px;
			}
		}
		.label-room-select,
		.label-paiement-select,
		.label-priceCategory-select {
			text-align: center;
			p {
				display: none;
			}
		}
		.label-paiement-select,
		.label-priceCategory-select {
			margin-top: -10px;
		}
		.paragraph-without-margin {
			margin: 5px 0;
			font-size: 14px;
			padding: 0 30px;
			color: #262957;
		}
	}
	.content-card-como {
		text-align: center;
	}
	.button-partner-container {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		.red-button {
			margin: 3px;
		}
	}
	.content-card-p {
		font-size: 14px;
		padding: 0 10px;
		color: #262957;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'NunitoSans';
	}

	.store-ubereats {
		flex-direction: column;
		span {
			opacity: 0.7;
			font-style: italic;
			font-size: 12px;
		}
	}
	.expand-left {
		width: calc(67% - 40px);
		transition: width 0.3s linear;
		position: absolute;
		z-index: 9;
		left: 0;
		margin: 0 20px;
	}
	.expand-center {
		width: calc(67% - 40px);
		transition: width 0.3s linear;
		position: absolute;
		z-index: 9;
		left: 16%;
		margin: 0 20px;
	}
	.expand-right {
		width: calc(67% - 40px);
		transition: width 0.3s linear;
		position: absolute;
		z-index: 9;
		right: 0;
		margin: 0 20px;
	}
	.expand-left-link {
		width: calc(100% - 40px);
		transition: width 0.3s linear;
		position: absolute;
		z-index: 9;
		left: 0;
		margin: 0 20px;
	}
	.expand-center-link {
		width: calc(100% - 40px);
		transition: width 0.3s linear;
		position: absolute;
		z-index: 9;
		left: 0;
		margin: 0 20px;
	}
	.expand-right-link {
		width: calc(100% - 40px);
		transition: width 0.3s linear;
		position: absolute;
		z-index: 9;
		right: 0;
		margin: 0 20px;
	}
	.expand-content {
		background-color: #fff;
		border-radius: 20px;
		position: relative;
		margin-top: 45px;
		transition: width 0.3s linear;
		padding: 16px;
		box-shadow: 0 6px 14px rgba(0, 0, 0, 0.23);
		display: flex;
		justify-content: space-around;
		max-height: 360px;
		h4 {
			color: #262957;
			font-family: 'FuturaBook';
			font-size: 26px;
			font-weight: 300;
			margin: 0;
		}
		p {
			color: #868686;
			font-family: 'FuturaBook';
			font-size: 17px;
			font-weight: 300;
			line-height: 20px;
			text-align: inherit;
			margin: 10px 0px;
		}
		.expand-external-content {
			width: 37%;
			min-width: 37%;
			margin-right: 16px;
			img {
				width: 100%;
				border-radius: 20px;
			}
			iframe {
				width: 100%;
				height: 317px;
			}
			.react-slideshow-container {
				justify-content: center;
				.react-slideshow-fade-wrapper {
					max-height: 340px;
				}
			}
			.react-slideshow-container + div.indicators > div.active {
				background: #e84249;
			}
			.react-slideshow-container + div.indicators > div:hover {
				background: rgba(232, 66, 73, 0.4);
			}
			.react-slideshow-container .nav span {
				border-color: #e84249;
			}
			.react-slideshow-container .nav:last-of-type {
				margin-left: -40px;
			}
			.react-slideshow-container .nav:first-of-type {
				margin-right: -40px;
			}
		}
		.expand-external-description {
			display: flex;
			min-width: 62%;
			flex-direction: column;
			overflow: scroll;
			p {
				font-family: 'NunitoSans';
				font-size: 15px;
				margin: 5px 0;
			}
			.missing-id-content {
				border-radius: 10px;
				> div:first-of-type {
					border-bottom: 1px solid rgba(0, 0, 0, 0.3);
				}
				overflow: hidden;
				.missing-id-suspends,
				.missing-id-actives {
					width: 30%;
					div {
						height: 140px;
						overflow-y: scroll;
					}
				}
				h5 {
					color: #262957;
					margin: 0;
					font-family: 'FuturaBook';
					font-size: 16px;
				}
				.actives_suspends_products {
					display: flex;
					width: 100%;
					justify-content: space-around;
					flex-wrap: wrap;
					text-align: center;
					p {
						color: #262957;
						width: 50%;
					}
				}
			}

			.incomplete_text_expand {
				margin-top: 20px;
				margin-bottom: 0;
			}
			.red-button {
				margin: 10px;
				text-align: center;
			}
		}
	}
	.after-left-link {
		&:after {
			content: '';
			position: absolute;
			left: 14%;
			top: -20px;
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 20px solid #fff;
			clear: both;
		}
	}
	.after-left {
		&:after {
			content: '';
			position: absolute;
			left: 20%;
			top: -20px;
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 20px solid #fff;
			clear: both;
		}
	}
	.after-center-link {
		&:after {
			content: '';
			position: absolute;
			left: 48%;
			top: -20px;
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 20px solid #fff;
			clear: both;
		}
	}
	.after-center {
		&:after {
			content: '';
			position: absolute;
			left: 48%;
			top: -20px;
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 20px solid #fff;
			clear: both;
		}
	}
	.after-right-link {
		&:after {
			content: '';
			position: absolute;
			right: 15%;
			top: -20px;
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 20px solid #fff;
			clear: both;
		}
	}
	.after-right {
		&:after {
			content: '';
			position: absolute;
			right: 25%;
			top: -20px;
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 20px solid #fff;
			clear: both;
		}
	}
}

.container-mews-select {
	display: flex;
	justify-content: center;
}
.select-mews-data {
	min-width: 170px;
}

@media only screen and (min-width: 2000px) {
	.react-slideshow-fade-wrapper {
		max-height: 610px !important;
	}

	.missing-id-suspends,
	.missing-id-actives {
		div {
			height: 320px !important;
		}
	}
}

.Mui-expanded {
	svg {
		color: #e84249;
	}
}

.content-card-store {
	display: flex;
	justify-content: space-between;
}

.content-card-store:last-child {
	margin-bottom: 50px;
}

.cardConfig-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 170px;
}
