@import '../../../../colors.scss';
#popup-event-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	*background-color: rgba(0, 0, 0, 0.5);
}
#popup-event-container {
	position: fixed;
	width: 1200px;
	height: 600px;
	top: 50%;
	left: 50%;
	margin-top: -300px; /* Negative half of height. */
	margin-left: -600px;
	padding: 0;
}

#popup-event {
	background-color: #fefefe;
	border-radius: 5px;
	padding: 15px;
	box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.15);
	height: 100%;
	width: 100%;
	overflow-y: auto;
	display: flex;
	margin-top: 0;
}

#popup-event-title {
	font-size: 25px;
	color: $blue-addition;
	font-family: 'FuturaBold';
	position: relative;
	top: -10px;
}
