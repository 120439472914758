@import "../../colors.scss";
.tabs-container {
  width: 100%;
  border-radius: 6px;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: 20px;

  a {
    width: 100%;
    text-decoration: none;
    color: #4a4a4a;
    text-align: center;
    p {
      color: #262957;
      font-family: "FuturaHeavy";
      font-size: 19px;
      line-height: 24px;
    }
  }
}

%line {
  content: "";
  position: absolute;
  margin-top: -3px;
  left: 0;
  right: 0;
  border-bottom: 2px solid #cecece;
}

.header-container {
  text-align: center;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  .active {
    p {
      color: $red-addition !important;
    }
    hr {
      width: 5rem;
      background-color: $red-addition;
      animation: separator-width 300ms ease-out forwards;
    }
  }
  .disabled-section {
    pointer-events: none;
    opacity: 0.6;
  }
}

@keyframes separator-width {
  0% {
    width: 0;
  }
  100% {
    width: 25%;
  }
}
