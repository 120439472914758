@import '../../../../../../../../../../colors.scss';

.form-category-supplement-info {
	display: flex;
	align-items: center;

	.form-category-supplement-title {
		color: $blue-addition;
		font-family: 'FuturaHeavy';
		*margin-right: 10px;
	}

	.form-category-supplement-title-disabled {
		color: #bebebe;
	}

	.form-category-supplement-type {
		color: $jaune;
		font-family: 'FuturaBook';
		font-style: italic;
		margin: 0 10px !important;
	}

	.form-category-supplement-type-config {
		display: flex;
		align-items: center;
		margin-left: auto;
		font-family: 'FuturaBook';

		> div {
			margin-right: 10px;
		}
	}

	.drag-category-icon {
		width: 24px;
		height: 24px;
	}

	.form-category-supplement-container {
		display: flex;
		align-items: center;
		p {
			margin: 0;
		}
	}

	.form-category-supplement-type-config {
		p {
			margin: 0;
		}
	}

	.menu-icon {
		fill: #e84249;
	}
}

.type-config-hidden {
	display: none !important;
}

.form-supplement-item {
	border-bottom: 1px solid #ebebeb !important;
	height: 40px;
	padding: 0 !important;
	span {
		font-family: 'FuturaBook';
		color: $blue-addition;
	}
}

.form-supplement-item-disabled span {
	color: #bebebe;
}

.supplement-button-panel {
	background: #fff;
	border: 1px solid #e7e7e7;
	color: $bleu-fonce;
	font-family: 'FuturaBook';
	padding: 5px 10px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 10px;
	p {
		margin: 0;
	}
	svg {
		margin-left: auto;
		color: $red-addition;
	}
}

.block {
	opacity: 1 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

.hidden {
	opacity: 0;
	width: 0;
	height: 0;
}

.supplement-modal-container {
	background: #fafafa;
	position: absolute;
	top: 0;
	left: 100%;
	transition: all 0.5s ease;
	z-index: 9;
}

.supplement-modal-container-product-in-menu {
	background-color: #f0f0f0;
	height: 400px !important;
	overflow: scroll;
}

.supplement-modal-actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.remove-extras-button {
	transition: background-color 200ms;
	border: solid 1px $blue-addition;
	border-radius: 5px;
	*cursor: pointer;
	height: 35px;
	margin-top: 10px;
}

.back-button {
	display: flex;
	align-items: center;
	font-family: 'FuturaBook';
	border-radius: 5px;
	color: $red-addition;
	margin: 10px;
	width: 10%;
	cursor: pointer;
	&:hover {
		color: $ciel-addition;
	}

	&:hover svg {
		color: $ciel-addition;
	}
}

.save-button {
	button {
		margin: 10px;
		font-family: 'FuturaBook';

		:hover {
			font-family: 'FuturaHeavy';
		}
	}
}

.display-flex div {
	display: flex;
	align-items: center;
}

.menu-product-icon {
	fill: #e84249 !important;
}

.category-list-container {
	padding: 0px 20px;
	.list-item-category {
		/* -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2); */
		cursor: pointer;
		/* margin-bottom: 5px;
    .supplement-active-checkbox {
      padding: 10px;
    } */
	}
}

.supplement-modal-product-name {
	font-style: italic;
	padding-left: 10px;
}
