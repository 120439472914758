@import '../../../../colors.scss';
.event-input {
	border: 1px solid #ced4da;
	padding: 10px 12px;
	font-size: 16px;
	transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 4px;
	background-color: #fff;
}

.param-line {
	height: 50px;
}
.param-line-delete {
	cursor: pointer;
	color: $red-addition;
	position: relative;
	top: 38px;
}
