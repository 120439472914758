.inactif-card {
	box-shadow: 0 3px 7px #979797 !important;
	background-color: #f6f6f6 !important;
	&:hover {
		i {
			box-shadow: 0 3px 7px #979797;
		}
		.bottom-card p {
			color: #868686 !important;
		}
	}
}

.logo-and-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	img {
		width: 50%;
		position: relative;
		left: 0;
	}
	p {
		margin: 0 1rem;
		color: #ababab;
		font-size: 17px;
		text-align: center;
	}
}

.card_top_action {
	position: absolute;
	top: 7px;
	right: 8px;
	z-index: 1;
	color: #cacaca !important;
	display: flex;
	flex-direction: row;
	width: 98%;
	justify-content: space-between;

	.card_action {
		cursor: pointer;
		color: #cacaca !important;
		margin-left: 10px;
		:hover {
			color: #5ac8f5;
		}

		svg: {
			width: 50px;
		}
	}

	.card_action_rigth {
		display: flex;
	}
}

.bottom-card {
	bottom: 0;
	position: absolute;
	width: 100%;
	display: flex;
	align-items: center;
	font-family: 'FuturaBook';
	font-size: 16px;
	color: #868686;
	p {
		margin-left: 1rem;
	}
	div {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		margin-left: auto;
		margin-right: 1rem;
		label {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			color: #868686;
		}
	}

	.form-switch {
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
		position: relative;
		bottom: 10px;
	}

	.form-switch i {
		position: relative;
		display: inline-block;
		margin-left: 1rem;
		width: 46px;
		height: 26px;
		background-color: #e6e6e6;
		border-radius: 23px;
		vertical-align: text-bottom;
		transition: all 0.3s linear;
	}

	.form-switch i::before {
		content: '';
		position: absolute;
		left: 0;
		width: 42px;
		height: 22px;
		background-color: #fff;
		border-radius: 11px;
		transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
		transition: all 0.25s linear;
	}

	.form-switch i::after {
		content: '';
		position: absolute;
		left: 0;
		width: 22px;
		height: 22px;
		background-color: #fff;
		border-radius: 11px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
		transform: translate3d(2px, 2px, 0);
		transition: all 0.2s ease-in-out;
	}

	.form-switch:active i::after {
		width: 28px;
		transform: translate3d(2px, 2px, 0);
	}

	.form-switch:active input:checked + i::after {
		transform: translate3d(16px, 2px, 0);
	}

	.form-switch input {
		display: none;
	}

	.form-switch input:checked + i {
		background-color: #5ac8f5;
	}

	.form-switch input:checked + i::before {
		transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
	}

	.form-switch input:checked + i::after {
		transform: translate3d(22px, 2px, 0);
	}
}

.partnerconfig-partner-title {
	font-weight: bold !important;
	font-size: 25px !important;
	color: #262957 !important;
	text-align: center !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
}

.partner-title-header {
	position: absolute;
	right: 10px;
}
