.notFoundContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #e84249;
	font-family: 'FuturaBook';
	height: 500px;
	margin-top: 10%;
	div {
		display: flex;
		margin-bottom: 50px;
	}

	span.number {
		font-size: 200px;
	}
}
