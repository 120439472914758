@import '../../../../../../../../../../../colors.scss';
.under-level-title-container {
	span {
		color: $blue-addition !important;
	}
	svg {
		fill: $blue-addition;
	}
	.menu-icon {
		fill: #e84249;
	}
	li {
		cursor: pointer;
	}
	.expandless-icon {
		fill: #e84249;
	}
	vertical-align: center;
}

.menu-level-item {
	span {
		color: $blue-addition !important;
	}
	svg {
		fill: $blue-addition;
	}
	.menu-icon {
		fill: #e84249;
	}
}

.under-level-hub-checkbox {
	display: flex;
	align-items: center;
}
