.gestion-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 630px;
}

.gestion-button-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
}