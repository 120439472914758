.content-container {
	z-index: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.module-container {
	width: 100%;
	display: flex;
	justify-content: center;
}
