@import '../../../../colors.scss';
.general-option-container {
	padding: 0px 25px 25px 25px;
}

.general-option-button {
	position: absolute !important;
	right: 50px;
	z-index: 100;
}
