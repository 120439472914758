@import '../../../../colors.scss';
.category-supplement-container {
	display: flex;
	flex-direction: column;
	padding: 20px 0px;
	min-width: 500px;
	svg {
		color: $blue-addition;
	}

	.category-supplement-info {
		display: flex;
		flex-direction: row;
		align-items: center;

		.name {
			color: $blue-addition;
			font-family: 'FuturaHeavy';
		}
	}

	.label-supplement {
		display: flex;
		border-bottom: 1px solid $blue-addition;
		color: $blue-addition;
		opacity: 0.6;
		margin: -5px 0 2px;
		span:first-child {
			width: 45%;
			text-align: center;
		}
		span:nth-child(2) {
			width: 30%;
			text-align: center;
		}
		span:nth-child(3) {
			width: 25%;
			text-align: center;
		}
	}
}

.input-category-supplement {
	display: flex;
	align-items: center;
	flex-grow: 5;
	justify-content: space-between;
}

.supplement-container {
	margin-left: 30px;
	.supplement-info {
		display: flex;
		flex-direction: row;
		align-items: center;

		border-bottom: 1px solid #e7e7e7;
		padding: 10px;

		.name {
			color: $blue-addition;
		}

		.check-name-supplement {
			display: flex;
			align-items: center;
			width: 45%;
			text-align: center;
		}

		.supplement-image-container {
			width: 30%;
			text-align: center;
			position: relative;
			button {
				width: 80px;
				height: 80px;
				border: 3px dashed rgba(232, 66, 73, 0.4);
				outline: 0;
				background-color: white;
				.icon-add-export {
					width: 20px;
					height: 20px;
					svg {
						font-size: 15px;
						cursor: pointer;
					}
				}
			}
			.supplement-image-overlay {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				height: 100%;
				width: 100%;
				opacity: 0;
				transition: 0.3s ease;
				background-color: rgba(255, 255, 255, 0.4);
				svg {
					cursor: pointer;
					color: $red-addition;
				}
			}
			&:hover {
				.supplement-image-overlay {
					opacity: 1;
				}
			}
		}

		.supp-price {
			width: 25%;
			text-align: center;
			//margin-left: auto;
		}
	}
}

.supplement-reset-button {
	svg {
		color: $red-addition;
	}
	margin-left: auto;
}

.input-supplement {
	padding: 5px 10px 5px 10px;
	margin-right: 20px;
	height: 27px;
	border-radius: 10px;
	display: flex;
	align-items: center;
}

.dialog-content-scroll {
	overflow-y: inherit !important;
}

.gestion-button-supplement {
	width: 300px;
	border-color: $red-addition !important;
	transition: all 300ms;
	span {
		font-family: 'FuturaBook';
		color: $red-addition;
	}
	&:hover {
		background-color: $red-addition !important;
		span {
			color: white;
		}
	}
}

.supplement-image-container {
	width: 30%;
	.supplement-image {
		width: 80px;
		height: 80px;
	}
}
