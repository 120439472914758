.variable-modal {
	display: flex;
	align-items: center;
	justify-content: center;
}

.variable-modal-container {
	background: white;
	padding: 20px;
	text-align: center;
	outline: none;
}

.export-item {
	display: flex;
	align-items: center;
}

#modal-title {
	color: #262957;
}
