.partner-configuration-card {
	.logo-and-description {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: calc(100% - 20px);
		justify-content: space-around;
		img {
			width: 50%;
			position: relative;
			left: 0;
			top: 0;
		}

		p {
			margin: 0 1rem;
			color: #ababab;
			font-size: 15px;
			text-align: center;
			overflow: scroll;
			height: 55px;
		}
	}
	&:hover {
		box-shadow: 0 6px 14px #979797;
		cursor: default;
		.bottom-card .configuration-button {
			cursor: pointer;
			color: #e84249 !important;
		}
		.bottom-card p {
			color: inherit !important;
		}
	}
	.connector_configuration {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: calc(100% - 20px);
		margin-top: 30px;
		justify-content: space-around;
		img {
			width: 25%;
			position: absolute;
			right: 15px;
			top: 5px;
			left: auto;
		}

		p {
			margin: 0 1rem;
			color: #ababab;
			font-size: 15px;
			text-align: center;
			overflow: scroll;
		}
		.connector_configuration_title {
			color: #7d7b7b;
			font-weight: bold;
		}

		.connector_configuration_button {
			background-color: white;
			color: #e84249;
			border: 1px solid #e84249;
			border-radius: 15px;
			font-size: 12px;
			height: 25px;
			padding: 2px 5px;
			display: flex;
			align-items: center;
			width: 80%;
			justify-content: center;
			cursor: pointer;
			text-align: center;

			&:hover {
				color: white;
				background-color: #e84249;
			}
		}
	}
	.princing-info {
		color: #262957;
		background-color: rgba(255, 255, 255, 0.5);
		border-radius: 10px;
		padding-left: 10px;
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 13px;
	}
}

.partnerconfig-partner-card .princing-info,
.partner-configuration-card .princing-info {
	color: #262957;
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 13px;
}

.bottom-card {
	bottom: 0;
	position: absolute;
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	font-family: 'FuturaBook';
	font-size: 16px;
	color: #868686;
	p {
		margin-left: 1rem;
	}
	.configuration-button {
		color: #e84249;
		margin-left: auto;
		margin-right: 1rem;
		display: flex;
		align-items: center;

		svg {
			margin-left: 2px;
			fill: #e84249;
		}
	}
}

.partner-config-lock-icon {
	position: inherit !important;
	width: 45px !important;
	margin-bottom: 10px;
}
