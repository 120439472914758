@import '../../../../colors.scss';

.partner-config-container {
	width: 680px;
	margin-top: 30px;
	margin-right: auto;
	margin-left: auto;
}

.partner-config-flex {
	margin-top: 10px;
	display: flex;
	justify-content: space-around;

	a {
		display: flex;
		align-items: center;
		border: 1px solid white;
		font-size: 13px;

		border-radius: 20px;
		background-color: $blue-addition;
		color: white;

		div {
			padding: 7px 25px 7px 25px;
			display: flex;
			align-items: center;
		}
	}

	.outlined {
		color: white;
		background-color: transparent;
		:hover {
			color: $blue-addition !important;
		}
	}

	a:hover {
		background-color: white;
		.outlined {
			background-color: white !important;
			color: $blue-addition !important;
		}
	}
}
