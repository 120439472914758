.MuiAccordionDetails-root {
	flex-direction: column;
}

#brand-channel-container {
	display: flex;
	flex-direction: column;
	p {
		color: #262957;
	}
	.red-button {
		margin: 0;
	}

	.MuiAccordionSummary-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.partner-channel-container {
	background-color: white;
	padding: 30px 20px;
	border-radius: 5px;
	width: fit-content;
	align-self: center;
}
