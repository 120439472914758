@import '../../../../colors.scss';
$light-blue: rgba(36, 42, 88, 0.7);
// secondary color
$yellow: #f9b868;
$dark-yellow: #f49f57;
$grey: #636363;
$info: #858588;
$light-grey: #a9aabc;
$light-grey2: #bdbdbd;
$light-green: #c6da85;

%icon-svg {
	vertical-align: super;
}

#hours {
	font-family: 'Futura';
	.form-hours {
		margin-bottom: 72px;
	}
	img {
		&.calendar {
			vertical-align: middle;
			margin-right: 0.9rem;
			width: 20px;
		}
	}
	.time-picker {
		display: flex;
		align-items: center;
		div {
			&.start,
			&.end {
				p {
					&.subtitle {
						margin-left: 0.5rem;
						letter-spacing: 0.5px;
					}
				}
				.arrow {
					width: 0.6rem;
					margin: 0 0.5rem;
					margin-bottom: 0.3rem;
				}
			}
			.select-styles {
				display: inline-block;
				margin: 0 0.5rem;
				border: 1px solid #ccc;
				width: 100px;
				border-radius: 5px;
				overflow: hidden;
				padding: 0.2rem 0.3rem;
				background: #fafafa url('../../../../img/fleche-bas.svg') no-repeat 90%
					50%;
				background-size: 13px;
				position: relative;
				.select {
					text-align-last: center;
					padding: 5px 8px;
					width: 90px;
					border: none;
					-webkit-box-shadow: none;
					box-shadow: none;
					background: transparent;
					background-image: none;
					-webkit-appearance: none;
					letter-spacing: 0.5px;
				}
				input {
					&.rc-time-picker-input {
						box-shadow: inherit;
						border: inherit;
						background: inherit;
						letter-spacing: 0.5px;
						color: $blue-addition;
						font-family: 'Futura';
						font-size: 16px;
					}
				}
			}
			.time {
				@extend %icon-svg;
				width: 25px;
				bottom: 0.6rem;
			}
		}
		.content-time {
			display: inline-block;
		}
	}
	.days {
		height: 30px;
		width: 285px;
		vertical-align: sub;
		background: #fafafa;
		span {
			border-right: 1px solid $light-grey;
			&:last-child {
				border-right: inherit;
			}
			span {
				border-right: inherit;
			}
		}
	}
	/* .content-days {
      margin-top: .2rem;
      color: $light-grey;
      font-weight: 100;
      font-size: 15px;
      button {
        &.selected {
          background: $ciel-addition;
          color: $grey;
        }
      }
    }  */
	.content-days {
		.select-days {
			display: inline-block;
			.bloc-day {
				color: $light-grey;
				background: white;
				border-right: 1px solid #cfd0d6;
				border-top: 1px solid $light-grey;
				border-bottom: 1px solid $light-grey;
				width: 50px;
				height: 40px;
				padding: 0.1rem 0;
				letter-spacing: normal;
				display: inline-block;
				&.selected {
					background: $ciel-addition;
					color: white;
				}
				span {
					display: block;
					padding-top: 0.5rem;
					text-align: center;
				}
				&:last-child {
					border-radius: 0 5px 5px 0;
				}
				&:first-child {
					border-radius: 5px 0 0 5px;
					border-left: 1px solid $light-grey;
				}
			}
		}
	}
}

.success {
	background-color: $ciel-addition;
	padding: 0.6rem;
	border-radius: 3px;
	margin: 1rem 0;
	color: white;
	text-align: center;
}

.subtitle {
	color: $grey;
	font-size: 16px;
	margin-bottom: 5px;
}

.rc-time-picker {
	display: inline-block;
	box-sizing: border-box;
	input {
		box-shadow: inherit;
		border: inherit;
		background: inherit;
	}
}
.rc-time-picker * {
	box-sizing: border-box;
}
.rc-time-picker-input {
	width: 100%;
	position: relative;
	display: inline-block;
	cursor: text;
	&[disabled] {
		color: #ccc;
		background: #f7f7f7;
		cursor: not-allowed;
	}
}
.rc-time-picker-panel-inner {
	display: inline-block;
	position: relative;
	outline: none;
	list-style: none;
	font-size: 12px;
	text-align: left;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 1px 5px #ccc;
	background-clip: padding-box;
	border: 1px solid #ccc;
	line-height: 1.5;
}
.rc-time-picker-panel-narrow {
	max-width: 113px;
}
.rc-time-picker-panel-input {
	margin: 0;
	padding: 0;
	width: 100%;
	cursor: auto;
}
.rc-time-picker-panel-input-wrap {
	box-sizing: border-box;
	position: relative;
	padding: 6px;
	border-bottom: 1px solid #e9e9e9;
}
.rc-time-picker-panel-input-invalid {
	border-color: red;
}
.rc-time-picker-panel-clear-btn {
	position: absolute;
	right: 6px;
	cursor: pointer;
	overflow: hidden;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	top: 6px;
	margin: 0;
}
.rc-time-picker-panel-clear-btn-icon {
	&:hover:after {
		color: #666;
	}
	&:after {
		content: 'x';
		font-size: 12px;
		font-style: normal;
		color: #aaa;
		display: inline-block;
		line-height: 1;
		width: 20px;
		transition: color 0.3s ease;
	}
}

.rc-time-picker-panel-select-active {
	overflow-y: auto;
}
.rc-time-picker-panel-select {
	float: left;
	font-size: 12px;
	border: 1px solid #e9e9e9;
	border-width: 0 1px;
	margin-left: -1px;
	box-sizing: border-box;
	width: 56px;
	max-height: 144px;
	overflow-y: auto;
	position: relative;
	color: $blue-addition;
	font-size: 'Futura';
	&:first-child {
		border-left: 0;
		margin-left: 0;
		overflow-x: hidden;
	}
	&:last-child {
		border-right: 0;
		overflow-x: hidden;
	}
	ul {
		list-style: none;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		width: 100%;
		li {
			list-style: none;
			box-sizing: content-box;
			margin: 0;
			padding: 0 0 0 16px;
			width: 100%;
			height: 24px;
			line-height: 24px;
			text-align: left;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			&:hover {
				background: #edfaff;
			}
		}
	}
}
li.rc-time-picker-panel-select-option-selected {
	background: #f7f7f7;
	font-weight: bold;
}
li.rc-time-picker-panel-select-option-disabled {
	color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
	background: transparent;
	cursor: not-allowed;
}

.hour-container {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	h4 {
		color: $blue-addition;
		font-size: 16px;
	}
}

.submit .red-button {
	text-align: right;
	margin: 0 24px;
}

.closing-date-container {
	display: flex;
	padding: 0;
	list-style: none;
	flex-wrap: wrap;
}

.closing-date-item-container {
	background-color: #fff;
	border-radius: 6px;
	color: #4a4a4a;
	box-shadow: 0 8px 16px rgba(1, 1, 1, 0.19);
	padding: 20px;
	margin: 10px;
}
