.custom-bubble-container {
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: absolute;
}

.custom-bubble-container img {
	position: absolute;
	width: 70px;
	height: 70px;
}

#meteor1 {
	top: -10%;
	left: 10%;
	width: 120px;
	height: 120px;
	animation-name: meteor1;
	animation-duration: 1800ms;
	animation-delay: 0ms;
	animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
}

#meteor2 {
	top: 0%;
	left: 10%;
	animation-name: meteor;
	animation-duration: 1200ms;
	animation-delay: 1000ms;
}

#meteor3 {
	top: -15%;
	left: 5%;
	animation-name: meteor;
	animation-duration: 1200ms;
	animation-delay: 1400ms;
}

#meteor4 {
	top: -10%;
	left: 10%;
	width: 70px;
	height: 70px;
	animation-name: meteor;
	animation-duration: 1200ms;
	animation-delay: 800ms;
}

#meteor5 {
	top: -10%;
	left: 0%;
	animation-name: meteor;
	animation-duration: 1200ms;
	animation-delay: 900ms;
}

/*** KEYFRAMES ***/

@keyframes meteor1 {
	0% {
		transform: translateY(0%);
	}
	100% {
		transform: translateY(3000%) translateX(3000%);
	}
}

@keyframes meteor {
	0% {
		transform: translateY(0%);
	}
	100% {
		transform: translateY(2500%) translateX(2500%);
	}
}
