.m-alert {
	display: flex;
	padding: 10px 30px;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.43;
	border-radius: 4px;
	letter-spacing: 0.01071em;
	background-color: transparent;
	width: 500px;
	align-items: center;
	margin-right: auto;
	margin-left: auto;
}
.m-alert-icon {
	margin-right: 15px;
}
.m-alert-warning {
	color: rgb(246, 149, 14);
	background-color: rgb(249, 225, 189);
}
.m-alert-success {
	color: rgb(74, 172, 59);
	background-color: rgb(212, 255, 214);
}
.m-alert-error {
	color: rgb(248, 85, 73);
	background-color: rgb(245, 171, 165);
}
.m-alert-info {
	color: rgb(0, 80, 155);
	background-color: #d6ebff;
}
