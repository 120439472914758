.dashboard-module {
	width: 30%;
	background-color: #fff;
	border-radius: 20px;
	margin: 20px;
	display: flex;
	justify-content: center;
	align-content: center;
	box-shadow: 0 6px 14px rgba(0, 0, 0, 0.23);
	min-height: 184px;
	position: relative;
	transition: all 0.3s linear;

	a {
		width: 100%;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	p {
		color: #262957;
		font-family: 'FuturaHeavy';
		font-size: 26px;
		transition: color 0.7s ease;
		&:after {
			margin-top: 8px;
			content: '';
			display: block;
			height: 6px;
			background: #e84249;
			width: 0;
			-webkit-transition: width 0.7s ease;
			-o-transition: ease width 0.7s;
			transition: width 0.7s ease;
		}
	}

	p.disabled {
		color: #acacac;
	}
	&:hover {
		cursor: pointer;
		box-shadow: 0 12px 28px #979797;
		p {
			//color: #e84249;
			&:after {
				width: 30%;
			}
		}
	}
}
