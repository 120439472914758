@import '../../../../colors.scss';
#dialog-payment-types {
	text-align: center;
}

.gestion-button-container {
	button {
		width: 300px;
		border-color: white !important;
		transition: all 300ms;
		span {
			font-family: 'FuturaBook';
			color: white;
		}
		&:hover {
			background-color: white !important;
			span {
				color: $red-addition;
			}
		}
	}
}
