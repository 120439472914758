@import '../../../../colors.scss';

.gestion-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 630px;
}

.container-mews-default-setting {
	display: flex;
	margin-top: 30px;
	margin-bottom: 30px;
	background-color: white;
	border-radius: 4px;
	padding: 20px 60px;
}

.container-mews-documentation {
	display: flex;
	flex-direction: column;
	p {
		color: $blue-addition;
	}
}

.mewsDocLinks {
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-right: 3px;
	}
}
