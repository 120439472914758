.input-error-label {
	margin: 0;
	font-size: 0.75rem;
	text-align: left;
	margin-top: 8px;
	min-height: 1em;
	line-height: 1em;
	color: red;
	width: 175px;
}
