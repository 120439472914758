#brand-channel-container {
	width: 680px;
	h3 {
		display: flex;
		.red-button {
			margin-left: 20px;
		}
	}

	min-width: 680px;
	margin-top: 30px;
	margin-right: auto;
	margin-left: auto;
}
