@import '../../../../../../colors.scss';
.new-export-button-container {
	width: 100%;
	display: flex;
}

.add-export-container {
	width: 633px;
	height: 150px;
	border: 3px dashed rgba(232, 66, 73, 0.7);
	border-radius: 10px;
	cursor: pointer;
	margin: 50px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	p {
		margin: 0 auto;
		color: #292929;
		font-size: 20px;
		display: flex;
		justify-content: center;
	}
}

.icon-add-export-card {
	position: relative;
	top: 10px;
	background-color: $red-addition;
	width: 80px;
	height: 80px;
	border-radius: 40px;
	svg {
		font-size: 60px;
		margin-top: 10px;
		margin-left: 0px;
		color: white;
	}
}
