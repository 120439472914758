.list-item-container {
	span {
		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}

.under-level-title {
	span,
	input {
		color: #262957;
		font-family: 'futuraHeavy' !important;
	}
}

.under-level-container {
	margin: 0;
}

.list-item-product-disabled {
	opacity: 0.5;
}
