@import '../../../../../../colors.scss';
.container {
	padding: 0;
	display: inline-block;
	height: 335px;
	.red-button {
		margin: 0;
		position: absolute;
		right: 20px;
		bottom: 20px;
	}
}
.DayPicker-Day {
	&:focus {
		outline: 0;
	}
}
.DayPicker-Day--today {
	color: $red-addition;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	background-color: $ciel-addition;
	&:focus {
		outline: 0;
	}
	&:hover {
		background-color: rgba(90, 200, 245, 0.8);
	}
}
.Selectable
	.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	background-color: #dbf8ff;
	color: $blue-addition;
}
.Selectable .DayPicker-Day {
	border-radius: 0;
}
.Selectable .DayPicker-Day--start {
	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;
}
.Selectable .DayPicker-Day--end {
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
}
.DayPicker-Months {
	margin-left: 6px;
}
.DayPicker-wrapper {
	&:focus {
		outline: 0;
	}
}
.DayPicker-Caption {
	div {
		font-size: 1rem;
		font-family: 'FuturaBook';
		margin-top: 0.3rem;
	}
}
.DayPicker-NavBar {
	span {
		&.DayPicker-NavButton--next {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: $red-addition url('../../img/arrow-white-right.svg') no-repeat
				center;
			background-size: 5px;
			&:focus {
				outline: 0;
			}
		}
		&.DayPicker-NavButton--prev {
			margin-right: 1.7rem;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: $red-addition url('../../img/arrow-white-left.svg') no-repeat
				center;
			background-size: 5px;
			&:focus {
				outline: 0;
			}
		}
	}
}
