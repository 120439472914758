@import '../../colors.scss';
.error-boundary {
	width: 70%;
	margin: 100px auto 20px auto;
	text-align: center;

	img {
		width: 200px;
	}

	h4 {
		color: $bleu-fonce;
	}

	p {
		color: $bleu-fonce;
		font-size: 15px;
	}
}
