.list-menu-container {
	width: 80%;
	&:after {
		content: '';
		display: table;
		clear: both;
	}

	$spCol: #e84249;
	.sp {
		width: 16px;
		height: 16px;
		clear: both;
		margin: 0 auto;
	}

	/* Spinner Circle Rotation */
	.sp-circle {
		border: 4px rgba($spCol, 0.25) solid;
		border-top: 4px rgba($spCol, 1) solid;
		border-radius: 50%;
		-webkit-animation: spCircRot 0.6s infinite linear;
		animation: spCircRot 1s infinite linear;
	}
	@-webkit-keyframes spCircRot {
		from {
			-webkit-transform: rotate(0deg);
		}
		to {
			-webkit-transform: rotate(359deg);
		}
	}
	@keyframes spCircRot {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(359deg);
		}
	}
}

.description-menu-selector {
	color: #262957;
}
