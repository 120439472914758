@import '../../../../../../colors.scss';
.closing-days {
	display: flex;
	justify-content: center;
	margin: 10px;
	.start-date-container,
	.end-date-container {
		display: flex;
		justify-content: center;
		align-items: center;
		.start,
		.end {
			display: flex;
			justify-content: center;
			.select-styles {
				border: 1px solid #ccc;
				width: 100px;
				border-radius: 5px;
				overflow: hidden;
				padding: 0.2rem 0.3rem;
				background-size: 13px;
				position: relative;
				display: inline-block;
				background-color: #fff;
				.select {
					text-align-last: center;
					padding: 5px 8px;
					width: 90px;
					border: none;
					box-shadow: none;
					background: transparent;
					letter-spacing: 0.5px;
					color: $blue-addition;
					font-size: 15px;
					font-family: 'Futura';
					&:focus {
						outline: 0;
					}
				}
			}
		}
	}
	.start-title,
	.end-title {
		width: 10%;
		text-align: center;
	}
}

#settings {
	.red-button {
		text-align: center;
	}
}

#calendar {
	background-color: white;
	border-radius: 10px;
	box-shadow: 0px 3px 8px 0px #bfbcbc;
	position: relative;
	width: 325px;
	display: flex;
	justify-content: center;
	margin: 0 auto;
	.close-icon-calendar {
		margin-right: 1.7rem;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: $blue-addition;
		background-size: 5px;
		position: absolute;
		top: 20px;
		left: 10px;
		fill: #fff !important;
		cursor: pointer;
		z-index: 4;
	}
	&.index-0 {
		bottom: 10.2rem;
	}
	&.index-1 {
		bottom: 7.2rem;
	}
	&.index-2 {
		bottom: 4.2rem;
	}
	&.index-3 {
		bottom: 1.2rem;
	}
	.valid-date {
		margin: 0;
	}
	.DayPicker-Caption {
		div {
			font-family: 'FuturaBook';
		}
	}
}

.arrow {
	width: 0.6rem;
	margin: 0 0.5rem;
	vertical-align: super;
}

.close-icon {
	margin-left: 0.3rem;
	position: relative;
	fill: #262957 !important;
	cursor: pointer;
}
