.dates-icon,
.info-restau-icon,
.pictures-icon {
	width: 30px;
	height: 30px;
}

.grey-color {
	fill: #7e8198;
}

.red-color {
	fill: #e84249;
}
