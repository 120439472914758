@charset "UTF-8";
.export-parent-container {
  position: relative; }
  .export-parent-container h2 {
    font-size: 22px; }
  .export-parent-container .exportModal-container {
    display: flex;
    align-items: center; }
  .export-parent-container .export-modal-container {
    min-height: 80vh;
    display: flex;
    flex-direction: row; }
    .export-parent-container .export-modal-container .recap-container {
      width: 20%;
      margin: 10px;
      z-index: 10;
      background-color: #fff; }
    .export-parent-container .export-modal-container .section-category {
      width: 20%;
      margin: 10px 10px 10px 0; }
    .export-parent-container .export-modal-container .section-products {
      width: 60%;
      margin: 10px; }
  .export-parent-container .valid-button-fixed {
    position: absolute !important;
    bottom: 20px;
    right: 20px;
    font-family: 'futuraBold';
    letter-spacing: 0.4px;
    width: 120px; }
  .export-parent-container .close-export-modal-icon {
    margin-left: auto;
    width: 24px;
    height: 24px;
    cursor: pointer; }

.middle-content-container {
  background: #fafafa;
  height: 97%;
  position: relative;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }
  .middle-content-container .form-product-container {
    padding: 20px 10px 0 10px; }
  .middle-content-container .section-label {
    font-family: 'FuturaBook';
    color: #262957;
    margin: 0; }
  .middle-content-container #product-label-with-tva {
    line-height: 25px;
    font-weight: 700;
    *text-align: center; }
    .middle-content-container #product-label-with-tva span {
      font-weight: initial; }
  .middle-content-container .section-label-red {
    color: #e84249; }
  .middle-content-container .no-section-selected {
    text-align: center;
    font-size: 20px;
    padding-top: 20px; }
  .middle-content-container .list-without-margin li {
    cursor: pointer; }

.product-empty {
  text-align: center;
  color: #ababab;
  font-size: 2em; }

.section-list-container ul {
  padding: 0; }

.section-list-container .section-list {
  background: #f6f6f6;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2); }
  .section-list-container .section-list .menu-icon {
    color: #e84249; }

.section-list-container .new-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 30px;
  font-family: 'FuturaBook';
  font-size: 15px;
  color: #e84249; }
  .section-list-container .new-section-container p {
    margin-right: 5px; }
  .section-list-container .new-section-container svg {
    font-size: 20px; }

.list-item,
.product-item {
  z-index: 9999999;
  align-items: center;
  font-family: 'FuturaBook';
  list-style-type: none; }

.section-item-container {
  width: 100%;
  color: #fff; }
  .section-item-container > div:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
    .section-item-container > div:first-child:hover {
      background-color: rgba(90, 200, 245, 0.2); }
  .section-item-container > div > div {
    padding: 0 !important;
    color: #fff !important;
    background-color: none !important; }
    .section-item-container > div > div span {
      font-family: 'FuturaBook';
      font-size: 18px;
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inherit; }
  .section-item-container .list-divider {
    background-color: #fff; }
  .section-item-container .product-list-container ul {
    padding: 0;
    margin: 0; }

.list-item .product-item > li > div {
  padding-left: 40px; }
  .list-item .product-item > li > div:hover {
    background-color: rgba(90, 200, 245, 0.1); }

.list-item .product-item > li > div > div {
  padding: 0 !important;
  color: #e84249; }
  .list-item .product-item > li > div > div span {
    color: #262957;
    font-family: 'FuturaBook';
    font-size: 15px; }
    .list-item .product-item > li > div > div span .menu-product-icon {
      width: 18px;
      height: 18px;
      color: #e84249; }

.list-item .product-item .close-icon-container {
  width: 18px;
  margin-right: 5px; }
  .list-item .product-item .close-icon-container:hover {
    cursor: pointer;
    background-color: transparent; }

.list-item .list-divider {
  background-color: #fff; }

.white span {
  color: #fff !important; }
  .white span svg {
    color: #fff !important; }

.blue {
  color: #262957; }
  .blue span {
    color: #262957 !important; }

.product-selected {
  background-color: rgba(90, 200, 245, 0.2) !important; }
  .product-selected span {
    font-family: 'FuturaHeavy' !important; }

.recap-export-container {
  font-family: 'FuturaBook';
  color: #262957; }
  .recap-export-container .section-title {
    font-family: 'FuturaHeavy'; }
  .recap-export-container li {
    list-style-type: none;
    line-height: 25px; }
    .recap-export-container li p {
      margin: 0;
      font-style: italic; }
  .recap-export-container .empty-item {
    display: flex;
    align-items: center; }
    .recap-export-container .empty-item::before {
      content: '• ';
      color: #b30015;
      margin-left: -14px;
      margin-right: 5px; }
    .recap-export-container .empty-item p {
      color: #b30015; }
  .recap-export-container .disabled-item {
    text-decoration: line-through; }
  .recap-export-container .empty-section {
    font-style: italic; }
  .recap-export-container .incomplete-string {
    color: #b30015;
    font-style: italic;
    text-align: center; }

.form-section-container .section-header-container {
  font-family: 'futuraBook';
  display: flex; }
  .form-section-container .section-header-container .input-name-container .section-label {
    color: #262957;
    margin: 0;
    padding: 20px 0 10px 10px; }
  .form-section-container .section-header-container .input-name-container .input-name {
    margin-left: 10px;
    display: flex;
    align-items: center;
    background: #fff;
    color: #262957; }
    .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield {
      margin: 0;
      padding: 0; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield > div:before {
        border: 0;
        content: none; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield > div:after {
        border: 0;
        content: none; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield > div > input {
        margin-bottom: 10px;
        padding: 0 5px;
        color: #ababab;
        font-family: 'futuraBook'; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield > div > textarea {
        padding: 5px; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield :focus {
        outline: -webkit-focus-ring-color auto 5px;
        color: #292929; }
    .form-section-container .section-header-container .input-name-container .input-name .save-section-icon {
      color: #e84249;
      padding: 3px;
      cursor: pointer; }
    .form-section-container .section-header-container .input-name-container .input-name .save-section-icon-disabled {
      opacity: 0.3; }
  .form-section-container .section-header-container .input-name-container .input-description {
    width: 250px; }
  .form-section-container .section-header-container .delete-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    padding-right: 10px;
    color: #e84249;
    cursor: pointer; }
    .form-section-container .section-header-container .delete-section-container .section-label {
      margin: 0;
      padding: 20px 10px 10px 0; }
    .form-section-container .section-header-container .delete-section-container .delete-section-icon {
      font-size: 30px; }

.form-section-container .product-selector-container {
  text-align: right; }
  .form-section-container .product-selector-container > div {
    padding: 30px 0 15px 0; }

.form-section-container ul {
  padding: 10px;
  margin: 0;
  overflow: auto;
  *height: 67vh; }
  .form-section-container ul li {
    list-style: none; }
    .form-section-container ul li hr {
      border-color: rgba(247, 247, 247, 0.3); }
    .form-section-container ul li .product-item-container {
      display: flex;
      align-items: center;
      color: #262957;
      padding: 0 10px; }
      .form-section-container ul li .product-item-container .product-item-close-icon {
        margin-left: auto;
        color: #e84249;
        width: 18px;
        cursor: pointer; }
