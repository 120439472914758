.modalExpensive {
	width: 30% !important;
	min-width: 750px;
	transition: all 1s ease;
}
.modalExpensiveFull {
	width: 100% !important;
}

.expensive-container {
	padding: 24px 24px 0 24px;
	flex: 1 1 auto;
}
