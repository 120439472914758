@import '../../../../../../colors.scss';

.gallery {
	/* width: 100%;
  padding: 0 8px;
  margin-bottom: 16px; */
	cursor: all-scroll;
	img {
		width: 100%;
		height: auto;
	}
}

.card-grid {
	width: 300px;
	height: 181px;
	overflow: hidden;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	position: relative;
	img {
		cursor: pointer;
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		-ms-transition: all 1s ease;
		transition: all 1s ease;
		width: 450px;
		-webkit-transform: translate(-10%, -10%);
		-ms-transform: translate(-10%, -10%);
		transform: translate(-10%, -10%);

		&:hover {
			width: 400px;
		}
	}
}

div.card-description {
	width: 270px;
	height: 95px;
	padding: 15px;
	text-align: center;
	background: #fff;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	font-family: 'FuturaBook';
	font-size: 16px;
	color: $blue-addition;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	position: relative;
	.content-name {
		line-height: 2px;
	}
	.content-description {
		width: 80%;
		margin: 0 auto;
		color: #b7b7b7;
	}
	.trash-container {
		position: absolute;
		bottom: 5px;
		right: 5px;
	}
}

.qrcode-container {
	position: absolute;
	bottom: 5px;
	left: 5px;
}

.card {
	list-style: none;
	margin: 0 23px 30px 23px;
	a {
		color: $red-addition !important;
	}
	.edit-picture-button {
		position: absolute;
		z-index: 1;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		border: none;
		color: $red-addition;
		top: 5px;
		left: 5px;
		cursor: pointer;
		transition: all 500ms;
		background-color: white;
		box-shadow: #b7b7b7;
		-webkit-box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.25);
		box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.25);
	}

	.edit-picture-button:hover {
		background-color: $red-addition;
		color: white;
	}
}
