@import '../../../../colors.scss';

.row {
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
}

.list-container {
	max-width: 100%;
	margin: 0 auto;
	ul {
		display: flex;
		padding: 0;
		list-style: none;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.add-picture-container {
	width: 300px;
	margin: 0 23px 30px 23px;
}

.container-title-load-qrcode {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}
