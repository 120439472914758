@import '../../../../colors.scss';
.event-container {
	background-color: #fff;
	padding: 5px 5px 20px 20px;
	border-radius: 4px;
	color: #292929;
	width: 100%;
	.checked-icon {
		margin-top: 40px;
		color: #7cc4ac;
	}
}

.input-error-label-fullwidth {
	width: 400px;
}

.webhook-action {
	text-align: right;
	text-transform: 'none';
}

.webhook-action button {
	margin-left: 30px;
}

.webhook-section {
	margin-bottom: 40px;
}

.webhook-section-title {
	color: $blue-addition;
	font-size: 20px;
}

.webhook-param {
	display: flex;
	justify-content: space-between;
}

.webhook-param p {
	color: $blue-addition;
	font-style: italic;
	font-size: 15px;
	text-align: left;
	margin-top: 0;
}

.webhook-delete {
	font-weight: bold;
	float: right;
}

.webhook-param-title p {
	font-style: normal;
	opacity: 0.8;
}

.webhook-param-description {
	color: $blue-addition;
	font-size: 18px;
	text-align: left;
}

.webhook-header-body {
	width: 60%;
}
.webhook-params {
	width: 40%;
}

.webhook-input-method {
	border: 1px solid #ced4da;
	padding: 3px 15px;
	font-size: 16px;
	transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 4px;
	background-color: #fff;
	border-bottom: 0;
}

.webhook-input-method::before {
	content: '';
}

.collapse-container {
	background: #f6f6f6;
	border-radius: 10px;
	div {
		div {
			display: flex;
			font-family: 'FuturaBook';
			font-size: 16px;
			color: #262957;

			.test-content {
				padding: 10px;
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				border-right: 1px solid;
				p {
					margin: 0;
					text-align: justify;
				}
				button {
					width: 25%;
					margin: 15px auto 0px auto;
					color: rgba(232, 66, 73, 1);
					border: 1px solid rgba(232, 66, 73, 0.5);
					&:hover {
						border: 1px solid rgba(232, 66, 73, 1);
						background-color: rgba(232, 66, 73, 0.08);
					}
					span {
						font-family: 'FuturaBold';
						font-size: 15px;
					}
				}
			}
			.test-result-content {
				padding: 10px;
				width: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				> div {
					width: 100%;
				}
				.button-bold {
					margin-right: 8px;
				}
			}
		}
	}
}
