.one-menu-item {
	display: flex;
	align-items: center;
	float: left;
	width: 23%;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
	margin: 1%;
	box-shadow: 0 6px 14px rgba(0, 0, 0, 0.23);
	position: relative;
	h4 {
		color: #262957;
	}
	p {
		font-size: 14px;
		color: #262957;
	}
	.item-description-response {
		color: #5ac8f5;
	}
	.item-description {
		width: 100%;
		padding: 20px 20px 10px 20px;
		h4 {
			margin: 0;
			margin-bottom: 30px;
		}
		p {
			margin: 0;
		}
		.item-description-response {
			margin-bottom: 10px;
			margin-top: 5px;
		}
	}
	&:hover {
		cursor: pointer;
	}
}

.item-selected {
	svg {
		position: absolute;
		fill: #5ac8f5;
		top: 0;
		right: 0;
		margin: 5px;
	}
	box-shadow: none;
}
