@import '../../../../colors.scss';

.externalIdAssignator {
	display: flex;
	color: white;
	font-size: 22px;
	width: 80%;
	text-align: center;
	align-items: center;
	flex-direction: column;
	margin-top: 40px;

	.externalIdAssignator-container {
		display: flex;
		flex-direction: column;
		min-width: 300px;

		.externalIdAssignator-head {
			border-bottom: 1px solid #292929;
			display: flex;
			justify-content: space-between;
			font-size: 25px;
		}
		.externalIdAssignator-body {
			display: flex;
			flex-direction: column;
		}
		.externalIdAssignator-row {
			justify-content: space-between;
			display: flex;
			margin: 0;

			p {
				margin: 10px;
			}
		}
	}

	.MuiSelect-select {
		color: white;
	}
}
