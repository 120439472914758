@import '../../../../../../colors.scss';

.new-picture-container {
	width: 294px;
	height: 300px;
	border: 3px dashed rgba(232, 66, 73, 0.4);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.label-file {
	cursor: pointer;
	font-family: 'FuturaBold';
	color: $red-addition;
	font-size: 19px;
	font-weight: bold;
	margin: 0 auto;
	margin-bottom: 16px;
}

// et on masque le input
.input-file {
	display: none;
}

.icon-add-export {
	margin-left: auto;
	margin-right: auto;
	background-color: $red-addition;
	width: 80px;
	height: 80px;
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		font-size: 60px;
		color: white;
	}
}
