@import '../../../../colors.scss';
.export-name-calendar-container {
	/* background: #fff;
  box-shadow: 0px 1px 0px 1px #cecece;
  -moz-box-shadow: 0px 1px 0px 1px #cecece;
  -webkit-box-shadow: 0px 1px 0px 1px #cecece; */
	width: 100%;
	padding: 5px 10px;
	border-radius: 5px;
	font-family: 'futuraBook';
	margin-right: 15px;

	.text-content {
		display: flex;
		align-items: center;
		color: $blue-addition;
		width: 300px;
		text-align: left;
		p,
		.exportName-textfield {
			margin: 0;
			font-family: 'futuraHeavy';
			font-size: 24px;
			width: 70%;
		}
		.export-modal-icon {
			margin-left: 10px;
			width: 24px;
			height: 24px;
			color: $red-addition;
			cursor: pointer;
		}
		.exportName-textfield {
			margin: 0;
			padding: 0;
			width: 70%;
			> div {
				&:before {
					border: 0;
					content: none;
				}
				&:after {
					border: 0;
					content: none;
				}
				> input {
					color: $blue-addition;
					font-family: 'futuraHeavy';
					font-size: 24px;
				}
			}
		}
		.DayPickerInput {
			input {
				font: inherit;
				border: 0;
				margin: 0;
				padding: 0;
				color: $red-addition;
				font-family: 'futuraBook';
				outline: 0;
				font-weight: 600;
				&::placeholder {
					color: $red-addition;
					font-weight: 600;
				}
			}
		}
	}
}
